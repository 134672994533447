import React from "react";
import Github from "../assets/projects/github-finder.png";
import Feedback from "../assets/projects/feedback-app.png";
import Reliq from "../assets/projects/reliq-mint.png";
import Abm from "../assets/projects/abm.png";
import ForbiddenFruit from "../assets/projects/forbidden-fruit.png";
import ZunigaCatering from "../assets/projects/zuniga-catering.png";

import { HashLink as Link } from "react-router-hash-link";

function Projects() {
  return (
    <div
      id="projects"
      className="flex flex-col justify-center h-auto lg:h-screen pt-28 lg:pt-0"
    >
      <h1 className="flex justify-center items-end mb-4">Projects</h1>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="project-container group">
          <img
            className="project-image"
            src={Abm}
            alt="Art By Minaenae Studio Website"
          />
          <Link className="project-link" to="https://www.artbyminaenae.com/">
            View Project
          </Link>
        </div>
        <div className="project-container group">
          <img
            className="project-image"
            src={ForbiddenFruit}
            alt="Forbidden Fruit Short Film Promotional Website"
          />
          <Link
            className="project-link"
            to="https://forbiddenfruit22.myapc.edu.ph/index.html"
          >
            View Project
          </Link>
        </div>
        <div className="project-container group">
          <img
            className="project-image"
            src={ZunigaCatering}
            alt="Zuniga Catering Ordering System"
          />
          <Link
            className="project-link"
            to="https://github.com/brentmanzo/hc-bagisto/tree/master/bagisto-bagisto-9a7315a"
          >
            View Project
          </Link>
        </div>
        <div className="project-container group">
          <img
            className="project-image"
            src={Github}
            alt="Github User Finder"
          />
          <Link
            className="project-link"
            to="https://github.com/brentmanzo/github-finder"
          >
            View Project
          </Link>
        </div>
        <div className="project-container group">
          <img
            className="project-image"
            src={Feedback}
            alt="Feedback Provider Form"
          />
          <Link
            className="project-link"
            to="https://feedback-ui-by-brent.netlify.app/"
          >
            View Project
          </Link>
        </div>
        <div className="project-container group">
          <img
            className="project-image"
            src={Reliq}
            alt="Reliq Mint Page for mobile browser screens"
          />
          <Link
            className="project-link"
            to="https://brentmanzo.github.io/reliq/"
          >
            View Project
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Projects;
