import React from "react";
import { Link } from "react-router-dom";

const logos = {
  react: {
    src: require("../assets/tech/react-icon.png"),
    alt: "React Logo",
    hover: "ReactJS",
  },
  nestjs: {
    src: require("../assets/tech/nestjs.png"),
    alt: "NestJS Logo",
    hover: "NestJS",
  },

  nodejs: {
    src: require("../assets/tech/nodejs-logo.png"),
    alt: "Node.js Logo",
    hover: "NodeJS",
  },

  mongodb: {
    src: require("../assets/tech/mongodb.webp"),
    alt: "MongoDB Logo",
    hover: "MongoDB",
  },
  nextjs: {
    src: require("../assets/tech/nextjs.png"),
    alt: "NextJS Logo",
    hover: "NextJS",
  },
  tailwind: {
    src: require("../assets/tech/tailwindcss.png"),
    alt: "TailwindCSS Logo",
    hover: "TailwindCSS",
  },
  azure: {
    src: require("../assets/tech/azure-logo.png"),
    alt: "Azure Logo",
    hover: "Azure",
  },
  netlify: {
    src: require("../assets/tech/netlify-logo.png"),
    alt: "Netlify Logo",
    hover: "Netlify",
  },
  vscode: {
    src: require("../assets/tech/vscode-logo.png"),
    alt: "VSCode Logo",
    hover: "VS Code",
  },
};

const certs = {
  intjs: {
    src: require("../assets/certifications/cert-intjs.png"),
    alt: "Intermediate JavaScript Certification",
  },
  azure: {
    src: require("../assets/certifications/cert-azure.png"),
    alt: "Azure Certification",
  },
  aws: {
    src: require("../assets/certifications/cert-aws.png"),
    alt: "AWS Certification",
  },
};

function EducationTech() {
  return (
    <div className="flex flex-col text-center items-center justify-center h-screen">
      {/* Education */}
      <div className="mb-8 font-semibold">
        <h1 className="mb-4">Education</h1>
        <p>BS Information Technology</p>
        <p className="italic"> Major in Mobile and Internet Technologies</p>
      </div>
      {/* Technologies */}
      <div>
        <h1 className="mb-8">Technologies</h1>
        <div className="grid grid-cols-3 gap-y-14 gap-x-14 lg:gap-x-44">
          {Object.values(logos).map((logo, index) => {
            return (
              <div
                className="group relative flex items-center justify-center"
                key={index}
              >
                <img
                  src={logo.src}
                  alt={logo.alt}
                  className="items-center justify-center h-16 w-16"
                />
                <span className="inset-x-[-2] transition-all absolute top-[70px] scale-0 z-10 rounded bg-gray-400 p-3 text-xs text-white group-hover:scale-100">
                  {logo.hover}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function Certifications() {
  return (
    <>
      <div className="text-center h-auto w-4/5 mb-10">
        <h3 className="font-bold mb-4">Certifications and Trainings</h3>

        <div className="grid grid-cols-2 gap-4 lg:gap-14">
          <div className="mb-4 md:mb-0">
            <Link to="https://www.udemy.com/certificate/UC-787cddef-c4ac-4789-90ea-dd2d4d4a7535/">
              <div className="cert-container">
                <img
                  src="https://udemy-certificate.s3.amazonaws.com/image/UC-787cddef-c4ac-4789-90ea-dd2d4d4a7535.jpg?v=1672926595000"
                  className="cert-img"
                  alt="React Front to Back"
                />
                <div className="cert-hover"></div>
              </div>
            </Link>
            <h2 className="text-xs md:text-sm px-4">
              React Front to Back 2022
            </h2>
          </div>

          <div className="mb-4 md:mb-0">
            <Link to="https://www.codecademy.com/profiles/cloud5763996966/certificates/512386fdc7f6c934f98b01e6afa8285a">
              <div className="cert-container">
                <img
                  src={certs.intjs.src}
                  className="cert-img"
                  alt="Codecademy: Intermediate JS"
                />
                <div className="cert-hover"></div>
              </div>
            </Link>
            <h2 className="text-xs md:text-sm px-4">
              Codecademy: Intermediate JS
            </h2>
          </div>
          <div className="mb-4 md:mb-0">
            <Link to="https://www.credly.com/badges/bbb3a567-4781-4b1e-866f-a43866bfd3bf">
              <div className="cert-container">
                <img
                  src={certs.azure.src}
                  className="cert-img"
                  alt="Azure Fundamentals"
                />
                <div className="cert-hover"></div>{" "}
              </div>
            </Link>
            <h2 className="text-xs md:text-sm px-4">
              Microsoft: Azure Fundamentals
            </h2>
          </div>
          <div className="mb-4 md:mb-0">
            <Link to="https://www.credly.com/badges/26679dbb-45a7-435d-b5f8-1d5a44eadc12">
              <div className="cert-container">
                <img
                  src={certs.aws.src}
                  className="cert-img"
                  alt="AWS Academy Graduate - AWS Academy Cloud Foundations"
                />
                <div className="cert-hover"></div>{" "}
              </div>
            </Link>
            <h2 className="text-xs md:text-sm px-4">
              AWS Academy Cloud Foundations
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}

function Background() {
  return (
    <div id="background" className="flex flex-col items-center justify-center">
      <EducationTech />
      <Certifications />
    </div>
  );
}

export default Background;
