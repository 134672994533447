import Sidebar from "./partials/Sidebar";
import Intro from "./partials/Intro";
import Projects from "./partials/Projects";
import Background from "./partials/Background";
import Contacts from "./partials/Contacts";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="flex">
        <Sidebar />
        <main className="container mx-auto px-6">
          <div className="flex flex-col mx-auto md:w-10/12">
            <Intro />
            <Projects />
            <Background />
            <Contacts />
          </div>
        </main>
      </div>
    </Router>
  );
}

export default App;
