import React from "react";
import profileimg from "../assets/brent-profile-bw.JPG";

function Intro() {
  return (
    <div className="flex flex-col justify-center md:flex-row min-h-screen">
      {/* Image */}
      <div className="flex justify-center items-end md:items-center mb-6 md:mb-2 md:w-2/5 px-4">
        <img
          className="rounded-lg shadow-xl min-w-[190px] w-4/5"
          src={profileimg}
          alt=""
        />
      </div>
      {/* Text */}

      <div className="flex flex-col justify-center items-center md:items-start px-6 md:w-3/5">
        <h1>Brent Millen Manzo</h1>
        <div className="flex flex-row my-1">
          <span className="mr-1 text-white inline-flex items-center justify-center px-2 py-1 leading-none bg-gray-600 rounded-full">
            Developer
          </span>
          <span className="mx-1 text-white inline-flex items-center justify-center px-2 py-1 leading-none bg-gray-400 rounded-full">
            Philippines
          </span>
        </div>

        <p className="my-2 px-4 md:px-0 text-justify lg:w-4/5">
          I built responsive web projects, managed internal applications and
          databases, and conducted unit testing to ensure quality code.
        </p>
      </div>
    </div>
  );
}

export default Intro;
