import React from "react";
import { HashLink as Link } from "react-router-hash-link";

function Sidebar() {
  return (
    <>
      {/* Top navbar */}
      <div className="fixed top-0 md:hidden bg-gray-800 text-gray-100 h-20 w-full z-20">
        <div className="flex flex-col justify-center items-center h-full text-xs ">
          <Link to="#">
            {" "}
            <h2 className="mb-2 font-semibold tracking-widest uppercase text-gray-400">
              {`< />`}
            </h2>
          </Link>
          <div className="flex space-x-3">
            <Link to="#projects">Projects</Link>
            <Link to="#background">Background</Link>
            <Link to="#contacts">Contacts</Link>
          </div>
        </div>
      </div>
      {/* Side navbar */}
      <aside className="hidden md:flex sticky left-0 top-0 h-screen p-6 w-60 bg-gray-800 text-gray-100">
        <nav className="space-y-8 text-sm">
          <div className="flex flex-col md:flex-row h-screen items-center space-y-2">
            <Link to="#">
              {" "}
              <h2 className="text-xs mb-14 font-semibold tracking-widest uppercase text-gray-400">
                {`< />`}
              </h2>
            </Link>

            <div className="flex flex-col">
              <div className="flex flex-col space-y-1 md:ml-8">
                <Link to="#projects">Projects</Link>
                <Link to="#background">Background</Link>
                <Link to="#contacts">Contacts</Link>
              </div>
              <div className="mt-7">
                <p className="text-xs dark:text-gray-400">
                  &#169; 2025 B. Manzo
                </p>
              </div>
            </div>
          </div>
        </nav>
      </aside>
    </>
  );
}

export default Sidebar;
